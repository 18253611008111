// imports top
@import "config";
@import "normalize";
@import "reset";
@import "fonts";
@import "typography";
@import "form";
@import "modal";
@import "icomoon";
@import "select2.min";
@import "plugins";
body,
html {
  height: 100%;
}
body {
  color: var(--text-color);
  font: 16px/1.3 var(--primary-font);
  &.no-scroll {
    overflow: hidden;
  }
}
.wrapper {
  min-height: 100%;
  flex-flow: column nowrap;
  display: flex;
}
.main {
  width: 100%;
  max-width: 1170px;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  margin: 0 auto;
  &--flex {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }
}
.header {
  background: var(--wine-berry);
  padding: 16px 0;
  position: relative;
  &__wrap {
    padding: 0 0 0 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1 1 0;
    gap: 24px;
  }
  &__buttons {
    align-items: center;
    justify-content: center;
    display: inline-flex;
    gap: 10px;
  }
}
.site {
  &-hamburger {
    height: 32px;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity;
    margin: 0 0 0 auto;
    position: relative;
    align-items: center;
    display: none;
    &__box {
      width: 20px;
      height: 14px;
      position: relative;
      display: block;
    }
    &__inner {
      top: 50%;
      margin-top: -2px;
      display: block;
    }
    &__inner,
    &__inner:before,
    &__inner:after {
      width: 20px;
      height: 2px;
      background: var(--elements-color);
      position: absolute;
      transition: transform .15s ease;
    }
    &__inner:before,
    &__inner:after {
      content: "";
      display: block;
    }
    &__inner:before {
      top: -6px;
    }
    &__inner:after {
      top: -12px;
    }
    &--collapse &__inner {
      top: auto;
      bottom: 0;
      transition: transform .13s cubic-bezier(.55, .055, .675, .19) .13s, background var(--primary-transition);
      &:after {
        transition: top .2s .2s cubic-bezier(.33333, .66667, .66667, 1), opacity .1s linear, background var(--primary-transition);
      }
      &:before {
        transition: top .12s .2s cubic-bezier(.33333, .66667, .66667, 1), transform .13s cubic-bezier(.55, .055, .675, .19), background var(--primary-transition);
      }
    }
    &--collapse.active &__inner,
    a.active &--collapse &__inner {
      transform: translate3d(0, -5px, 0) rotate(-45deg);
      transition-delay: .22s;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
      &:after {
        top: 0;
        opacity: 0;
        transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s .22s linear;
      }
      &:before {
        top: 0;
        transform: rotate(-90deg);
        transition: top .1s .16s cubic-bezier(.33333, 0, .66667, .33333), transform .13s .25s cubic-bezier(.215, .61, .355, 1);
      }
    }
  }
  &-headline {
    font-family: arial, sans-serif;
    padding: 0 16px 30px 16px;
    span {
      white-space: pre-wrap;
      box-decoration-break: clone;
      padding: 5px 5px 5px 0;
    }
    &--light {
      color: var(--wine-berry);
      span {
        background-color: var(--elements-color);
        box-shadow: -16px 0 0 var(--elements-color);
      }
    }
    &--dark {
      color: var(--elements-color);
      font-family: arial, sans-serif;
      padding: 0 16px 30px 16px;
      span {
        background-color: var(--wine-berry);
        box-shadow: -16px 0 0 var(--wine-berry);
      }
    }
  }
  &-search {
    border-radius: var(--primary-radius);
    background: var(--wild-sand);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .10);
    padding: 16px;
    margin: -32px 0 40px 0;
    position: relative;
    align-items: center;
    display: flex;
    gap: 16px;
  }
}
.nav {
  &-main {
    margin: 0 auto;
    &__list {
      align-items: center;
      display: inline-flex;
      gap: 24px;
    }
    &__links {
      color: var(--elements-color);
      font: 16px/1.2 var(--primary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      display: inline-flex;
      &.active,
      &:hover {
        color: var(--deco-color);
      }
    }
  }
  &-share {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    &__links {
      color: var(--elements-color);
      font-size: 15px;
      text-decoration: none;
      transition: color var(--primary-transition);
      position: relative;
      display: inline-flex;
      &.active,
      &:hover {
        color: var(--deco-color);
      }
      &:after {
        content: "";
        position: absolute;
        inset: -4px;
      }
    }
  }
  &-bottom {
    &__list {
      align-items: center;
      display: inline-flex;
      flex-wrap: wrap;
      gap: 24px;
    }
    &__links {
      color: var(--elements-color);
      font: 16px/1.2 var(--primary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      display: inline-flex;
      &.active,
      &:hover {
        color: var(--deco-color);
      }
    }
  }
}
.body {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
}
.section {
  padding: 65px 0;
  &--hero {
    --text-color: var(--elements-color);
    color: var(--text-color);
    background: var(--wine-berry);
    padding: 55px 0 30px 0;
  }
  &--offers {
    padding: 0 0 65px 0;
  }
  &--why {
    --text-color: var(--elements-color);
    color: var(--text-color);
    background: var(--wine-berry);
    padding: 45px 0 0 0;
  }
  &--advantages {
    padding: 65px 0 40px 0;
  }
}
.hero {
  &-text {
    max-width: 450px;
    padding: 50px 0 75px 0;
    margin: 0 auto 0 0;
    flex: 1 1 0;
  }
  &-image {
    max-width: 50%;
  }
}
.offers {
  &-list {
    margin: 0 0 40px 0;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    display: grid;
    gap: 16px;
    &__items {
      background: var(--wild-sand);
      border-radius: var(--primary-radius);
      padding: 16px 16px 19px 16px;
      display: flex;
      flex-flow: column nowrap;
    }
    &__wrap {
      margin: 0 0 auto 0;
    }
    &__row {
      margin: 0 0 15px 0;
      justify-content: space-between;
      display: flex;
      gap: 13px;
    }
    &__logo {
      width: 140px;
      height: 64px;
      background: var(--elements-color);
      border-radius: var(--primary-radius);
      padding: 0 6px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &__discount {
      width: 88px;
      height: 64px;
      color: var(--elements-color);
      font: bold 24px/1.2 var(--primary-font);
      background: var(--secondary-deco-color);
      border-radius: var(--primary-radius);
      padding: 8px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
    }
    &__more {
      color: var(--deco-color);
      font: bold 16px/1.2 var(--primary-font);
      text-decoration: none;
      transition: color var(--primary-transition);
      align-items: center;
      display: inline-flex;
      gap: 8px;
      &:hover {
        color: var(--secondary-deco-color);
      }
    }
  }
}
.why {
  &-text {
    max-width: 570px;
    padding: 0 30px 50px 0;
    margin: 0 auto 0 0;
    flex: 1 1 0;
  }
  &-image {
    max-width: 50%;
    align-self: flex-end;
  }
}
.advantages {
  &-image {
    max-width: 50%;
  }
  &-text {
    max-width: 580px;
    padding: 0 0 0 20px;
    margin: 0 0 0 auto;
    flex: 1 1 0;
  }
}
.cupon {
  &-image {
    width: 100%;
  }
  &-text {
    background: var(--wild-sand);
    padding: 26px 16px 5px 16px;
    position: relative;
    p {
      font: 14px/1.44 var(--primary-font);
    }
  }
  &-logo {
    width: 132px;
    height: 40px;
    background: var(--elements-color);
    border-radius: 5px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .10);
    transform: translateY(-50%);
    padding: 8px 16px;
    position: absolute;
    top: 0;
    left: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &-info {
    min-height: 180px;
    padding: 24px 16px;
    position: relative;
    align-items: flex-start;
    display: flex;
    gap: 16px;
    &__text {
      flex: 1 1 0;
    }
    &__top {
      color: var(--elements-color);
      text-align: center;
      background: rgba(53, 18, 45, .80);
      backdrop-filter: blur(6px);
      padding: 20px 16px 0 16px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-flow: column nowrap;
      position: absolute;
      inset: 0;
    }
    &__buttons {
      padding: 0 0 15px 0;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      gap: 10px;
    }
    p {
      font: 14px/1.44 var(--primary-font);
    }
  }
}
.authentication {
  &-col {
    width: 50%;
    padding: 40px;
    &--bg {
      background: var(--wild-sand);
    }
  }
}
.footer {
  color: var(--elements-color);
  font: 16px/1.2 var(--primary-font);
  background: var(--wine-berry);
  padding: 27px 0;
  &__copy {
    padding: 0 24px;
    margin: 0 0 0 auto;
  }
}
// imports bottom
@import "tablet";
@import "mobile";
@import "helpers";