// custom select
.select2-container {
  .site-search & {
    max-width: 488px;
    margin: 0 0 0 auto;
    @media (max-width: $mobile-max) {
      max-width: none;
    }
  }
  &.select2-container--focus {
    .select2-selection--single {
      border-color: var(--focus-color);
    }
  }
  .select2-selection--single {
    height: 48px;
    color: var(--text-color);
    font: 16px var(--primary-font);
    border: 1px solid var(--border-color);
    border-radius: var(--primary-radius);
    .select2-selection__rendered {
      line-height: 46px;
      padding-left: 16px;
      padding-right: 40px;
    }
    .select2-selection__arrow {
      width: 40px;
      height: auto;
      bottom: 1px;
    }
    .site-search & {
      height: 40px;
      border-radius: 20px;
      .select2-selection__rendered {
        line-height: 38px;
      }
    }
  }
  .select2-dropdown {
    border: 1px solid var(--focus-color);
    &.select2-dropdown--above {
      border-bottom: none;
    }
    &.select2-dropdown--below {
      border-top: none;
    }
    .select2-results__option {
      padding: 6px 16px;
      &.select2-results__option--highlighted {
        background: var(--deco-color);
      }
    }
  }
}