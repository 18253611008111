// media queries scss
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1169px;
$desktop-min: 1170px;

// helpers loop variables scss
$padding-top-bottom-from: 0;
$padding-top-bottom-to: 10;
$padding-top-bottom-step: 2;

$padding-left-right-from: 0;
$padding-left-right-to: 0;
$padding-left-right-step: 2;

$margin-top-bottom-from: 0;
$margin-top-bottom-to: 0;
$margin-top-bottom-step: 2;

$margin-left-right-from: 0;
$margin-left-right-to: 0;
$margin-left-right-step: 2;

// helpers loop padding and margin top left right bottom scss
$helpers-xl: false; // desktop
$helpers-sm: false; // tablet
$helpers-xs: false; // mobile

:root {
  /* colors with hex values */
  --black: #000;
  --white: #fff;
  --wine-berry: #35122D;
  --silver: #BFBFBF;
  --lola: #E8DDE5;
  --silver-chalice: #a5a5a5;
  --mandy: #E74972;
  --schist: #A8B596;
  --petite-orchid: #DB8993;
  --vanilla-ice: #F1D0D4;
  --wild-sand: #F4F4F4;
  --monza: #d60017;

  /* reassign color vars to semantic color scheme */
  --text-color: var(--wine-berry);
  --secondary-text-color: var(--black);
  --elements-color: var(--white);
  --deco-color: var(--mandy);
  --secondary-deco-color: var(--schist);

  /* font */
  --primary-font: "Apercu Pro", sans-serif;
  --secondary-font: "Inter", sans-serif;

  /* other elements */
  --primary-transition: .2s linear;
  --placeholder-color: var(--silver);
  --focus-color: var(--black);
  --disabled-color: var(--silver-chalice);
  --border-color: var(--lola);
  --primary-radius: 5px;
  --error-color: var(--monza);
  --gutter: 15px;

  /* media queries css */
  --mobile-max: #{$mobile-max};
  --tablet-min: #{$tablet-min};
  --tablet-max: #{$tablet-max};
  --desktop-min: #{$desktop-min};
}

// mixin scss
@mixin last-child {
  & > *:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}