@font-face {
  font-family: "Apercu Pro";
  src: url("../fonts/Apercu-Pro-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Apercu Pro";
  src: url("../fonts/Apercu-Pro-Regular-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Apercu Pro";
  src: url("../fonts/Apercu-Pro-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Apercu Pro";
  src: url("../fonts/Apercu-Pro-Medium-Italic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Apercu Pro";
  src: url("../fonts/Apercu-Pro-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Apercu Pro";
  src: url("../fonts/Apercu-Pro-Bold-Italic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}