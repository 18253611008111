@media (max-width: $mobile-max) {
  body {
    min-width: 360px;
  }
  .header {
    &__wrap {
      text-align: center;
      opacity: 0;
      visibility: hidden;
      background: var(--wine-berry);
      transition: all var(--primary-transition);
      transform: translateY(-15px);
      border-bottom: 1px solid var(--elements-color);
      padding: 30px var(--gutter);
      flex-flow: column nowrap;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      &.open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }
  .site {
    &-logo {
      img {
        height: 32px;
      }
    }
    &-hamburger {
      display: inline-flex;
    }
    &-search {
      margin-top: -95px;
      flex-wrap: wrap;
      .btn {
        width: 100%;
      }
    }
  }
  .nav {
    &-main {
      padding: 10px 0 0 0;
      order: 1;
      &__list {
        flex-flow: column nowrap;
        gap: 35px;
      }
    }
    &-share {
      margin: 0 auto;
    }
    &-bottom {
      margin: 0 auto;
    }
  }
  .section {
    padding: 65px 0 65px 0;
    &--hero {
      padding: 30px 0 135px 0;
    }
    &--offers {
      padding: 0 0 40px 0;
    }
    &--why {
      padding: 45px 0 0 0;
    }
    &--advantages {
      padding: 45px 0 40px 0;
    }
  }
  .hero {
    &-text {
      max-width: none;
      padding: 35px 0 0 0;
      flex: 0 0 100%;
      order: 1;
    }
    &-image {
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .why {
    &-text {
      max-width: none;
      padding: 0 0 50px 0;
      margin: 0;
      flex: 0 0 100%;
    }
    &-image {
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .advantages {
    &-image {
      max-width: 100%;
      margin: 0 auto;
      order: 1;
    }
    &-text {
      max-width: none;
      padding: 0 0 40px 0;
      margin: 0;
      flex: 0 0 100%;
    }
  }
  .authentication {
    &-col {
      width: 100%;
      padding: 10px 15px 15px 15px;
    }
  }
  .footer {
    &__copy {
      width: 100%;
      text-align: center;
      padding: 20px 0;
      margin: 0;
    }
  }
}