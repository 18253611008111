@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .header {
    &__wrap {
      text-align: center;
      opacity: 0;
      visibility: hidden;
      background: var(--wine-berry);
      transition: all var(--primary-transition);
      transform: translateY(-15px);
      border-bottom: 1px solid var(--elements-color);
      padding: 30px var(--gutter);
      flex-flow: column nowrap;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      &.open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }
  .site {
    &-hamburger {
      display: inline-flex;
    }
  }
  .nav {
    &-main {
      padding: 10px 0 0 0;
      order: 1;
    }
  }
}