.modal {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  transition: all var(--primary-transition);
  background: var(--link-water);
  padding: var(--gutter);
  overflow: auto;
  position: fixed;
  inset: 0;
  z-index: 5;
  @media (max-width: $mobile-max) {
    padding: 0;
  }
  &.open {
    opacity: 1;
    visibility: visible;
  }
  &:before {
    height: 100%;
    content: "";
    vertical-align: middle;
    display: inline-block;
  }
  &__hold {
    width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  &__wrap {
    width: 100%;
    text-align: left;
    background: var(--elements-color);
    margin: 0 auto;
    isolation: isolate;
    position: relative;
    &--cupon {
      max-width: 464px;
    }
    &--authentication {
      max-width: 752px;
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__bg {
    background: rgba(0, 0, 0, 0.85);
    position: absolute;
    inset: 0;
    &:after {
      content: "";
      position: fixed;
      inset: 0;
    }
  }
  &__close {
    width: 24px;
    height: 24px;
    color: var(--text-color);
    font-size: 12px;
    text-decoration: none;
    transition: all var(--primary-transition);
    background: var(--elements-color);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    position: absolute;
    top: 12px;
    right: 12px;
    &:after {
      content: "";
      position: absolute;
      inset: -5px;
    }
    [class^=icon-] {
      transition: transform var(--primary-transition);
      display: inline-block;
    }
    &:hover {
      [class^=icon-] {
        transform: rotate(90deg);
      }
    }
  }
}