h1,
.h1 {
  font: bold clamp(40px, 5vw, 64px)/1.1 var(--primary-font);
  padding: 0 0 20px 0;
}
h2,
.h2 {
  font: bold clamp(32px, 5vw, 40px)/1.2 var(--primary-font);
  padding: 0 0 20px 0;
}
h3,
.h3 {
  font: bold 32px/1.2 var(--primary-font);
  padding: 0 0 20px 0;
}
h4,
.h4 {
  font: bold 20px/1.2 var(--primary-font);
  padding: 0 0 20px 0;
}
h5,
.h5 {
  font: 18px/1.32 var(--primary-font);
  padding: 0 0 20px 0;
}
h6,
.h6 {
  font: bold 16px/1.2 var(--primary-font);
  padding: 0 0 20px 0;
}
ul,
ol {
  &:not([class]) {
    font: 16px/1.5 var(--primary-font);
    padding: 0 0 8px 0;
    li {
      padding: 0 0 16px 0;
      &:before {
        display: inline-block;
      }
    }
  }
}
ul {
  &:not([class]) {
    li {
      &:before {
        width: 8px;
        height: 8px;
        content: "";
        background: var(--text-color);
        transform: rotate(45deg);
        margin: 0 15px 0 0;
        position: relative;
        top: -2px;
      }
    }
  }
}
ol {
  &:not([class]) {
    counter-reset: li;
    li {
      &:before {
        font-weight: bold;
        content: counter(li) ".";
        counter-increment: li;
        margin: 0 12px 0 0;
      }
    }
  }
}
p {
  font: 16px/1.5 var(--primary-font);
  padding: 0 0 24px 0;
}
a {
  &:not([class]) {
    color: var(--deco-color);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &.regular {
    color: var(--deco-color);
    text-decoration: none;
    transition: color var(--primary-transition);
    &.active,
    &:hover {
      color: var(--deco-color);
    }
  }
  &[href^="mailto:"],
  &[href^="http"] {
    word-break: break-all;
  }
  &:focus-visible {
    outline: 1px solid var(--deco-color);
  }
}
.text-color {
  color: var(--text-color) !important;
}
.text-color-deco {
  color: var(--deco-color) !important;
}
.text-color-error {
  color: var(--error-color) !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-normal {
  font-weight: normal !important;
}