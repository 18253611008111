.form {
  &-regular {
    display: flex;
    flex-flow: row wrap;
    gap: 18px 16px;
    &__items {
      width: 100%;
      &--split {
        width: calc(50% - 8px);
      }
      &--buttons {
        padding: 0 0 15px 0;
        align-items: center;
        display: inline-flex;
        gap: 10px;
      }
    }
    &__label {
      color: var(--text-color);
      font: 16px/1.2 var(--primary-font);
      padding: 0 0 11px 0;
      display: block;
    }
  }
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])),
textarea {
  width: 100%;
  color: var(--text-color);
  font: 16px var(--primary-font);
  transition: all var(--primary-transition);
  border: 1px solid var(--border-color);
  border-radius: var(--primary-radius);
  padding: 11px 16px 12px 16px;
}
textarea {
  height: 150px;
  resize: none;
  display: block;
  &[rows] {
    height: auto;
  }
}
input[type=number] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]))::placeholder,
textarea::placeholder {
  color: var(--placeholder-color) !important;
  opacity: 1;
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])):focus::placeholder,
textarea:focus::placeholder {
  color: transparent !important;
}
input:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])):focus,
textarea:focus {
  border-color: var(--focus-color);
}
input.error:where(:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])),
textarea.error {
  border: 1px solid var(--error-color) !important;
}
input[type=checkbox],
input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  & + label {
    min-height: 18px;
    font: 14px/1.2 var(--primary-font);
    cursor: pointer;
    white-space: pre-wrap;
    padding: 0 0 0 25px;
    position: relative;
    display: inline-block;
    &:before,
    &:after {
      content: "";
      box-sizing: border-box;
      background: var(--elements-color);
      border: 1px solid var(--border-color);
      transition: all var(--primary-transition);
      position: absolute;
      top: 0;
      left: 0;
    }
    &:before {
      width: 18px;
      height: 18px;
    }
    &:after {
      opacity: 0;
      visibility: hidden;
    }
  }
  &:checked {
    & + label {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.error {
    & + label {
      &:before {
        border-color: var(--error-color);
      }
    }
  }
  &:disabled {
    & + label {
      &:before {
        background: var(--border-color);
      }
    }
  }
}
input[type=checkbox] {
  & + label {
    &:before {
      border-radius: 3px;
    }
    &:after {
      width: 18px;
      height: 18px;
      color: var(--deco-color);
      font-size: 9px;
      font-family: icomoon;
      content: "\e90a";
      padding: 3px 0 0 1px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}
input[type=radio] {
  & + label {
    &:before {
      border-radius: 50%;
    }
    &:after {
      width: 8px;
      height: 8px;
      background: var(--deco-color);
      border-radius: 50%;
      top: 5px;
      left: 5px;
    }
  }
}
.btn {
  min-height: 40px;
  font: 16px/1.1 var(--primary-font);
  text-align: center;
  text-decoration: none;
  transition: all var(--primary-transition);
  border-radius: 100px;
  padding: 10px 24px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  gap: 12px;
  &--primary {
    color: var(--elements-color);
    background: var(--deco-color);
    &:hover {
      background: var(--petite-orchid);
    }
  }
  &--secondary {
    color: var(--deco-color);
    background: var(--elements-color);
    &:hover {
      background: var(--vanilla-ice);
    }
  }
  &--block {
    width: 100%;
  }
  &:disabled {
    color: var(--disabled-color);
    cursor: not-allowed;
    background: var(--disabled-color);
  }
}